<script lang="ts" setup>
const currentStore = useCurrentStore()
const isMobile = useIsMobile()
</script>

<template>
  <ClientOnly>
    <div class="relative mt-[58px] sm:mt-[72px] h-[calc(100vw/2.74)] w-full">
      <img
        alt=""
        class="h-full w-full object-cover rounded-b-lg"
        :src="
          optimizedImage(
            currentStore.backgroundImageUri || '/images/banner-image.jpg',
            isMobile ? 'medium' : 'large',
          )
        "
      />
    </div>
  </ClientOnly>
</template>
